<template>
  <v-card class="mt-2">
    <v-card-title>Свойства</v-card-title>
    <v-card-text>
      <div v-for="p in itemProps" :key="p.id">
        <v-row dense no-gutters justify="start">
          <v-col cols="6" lg="4">
            {{ p.name }}
          </v-col>
          <v-col cols="1" lg="1">-</v-col>
          <v-col cols="5" lg="3" class="d-flex font-weight-bold">
            <div class="d-flex">
              <div>{{ p.value }}</div>
              <div class="ml-1">{{ unitOfMeasureName(p.unitOfMeasure) }}</div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="!itemProps.length" class="font-italic">
        Пока ничего нет
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$router.back()">Назад</v-btn>
      <v-btn :to="{ name: 'item-props.edit', params: { id } }">Изменить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ItemPropsCard',
  props: {
    id: {
      type: String,
      required: true
    },
    itemProps: {
      type: [Array],
      required: false,
      default: () => []
    }
  },
  methods: {
    unitOfMeasureName(unitOfMeasure) {
      if (unitOfMeasure && unitOfMeasure.name) {
        return unitOfMeasure.name
      }

      return null
    }
  }
}
</script>
