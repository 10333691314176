<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-img
        v-if="item.image"
        :src="item.image | imageUrl('medium')"
        height="150"
        class="white--text align-end"
        style="position: relative"
      >
        <div class="d-block pa-2 black" style="opacity: 0.5; height: 50px"></div>
        <!--        <h2 style="position: absolute; bottom: 5px; left:10px;">{{ itemCode }} {{ item.name }}</h2>-->
        <div class="d-flex align-center" style="position: absolute; bottom: 5px; left:10px;">
          <h2>{{ itemCode }} {{ item.name }}</h2>
          <a class="d-block ml-2" :href="itemSiteLink" target="_blank"><v-icon>mdi-open-in-new</v-icon></a>
        </div>
      </v-img>
      <v-card-title v-else>{{ item.name }}</v-card-title>
      <v-card-text>
        <v-sheet :color="sheetColor">
          <v-row dense>
            <!--            <v-col>-->
            <!--              <div class="mb-2">-->
            <!--                <div class="text-caption grey&#45;&#45;text text&#45;&#45;darken-2">Код</div>-->
            <!--                <div>{{ itemCode }}</div>-->
            <!--              </div>-->
            <!--            </v-col>-->
            <v-col>
              <text-line-bool title="Статус" :value="item.isActive" label-for-true="Активно" label-for-false="Заблокировано"></text-line-bool>
            </v-col>
            <v-col>
              <text-line-bool title="Опубликовано" :value="item.isPublic"></text-line-bool>
            </v-col>
            <v-col>
              <text-line-bool title="Наличие" :value="item.isInStock"></text-line-bool>
            </v-col>
          </v-row>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Название</div>
            <div>{{ item.name }}</div>
          </div>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Состав</div>
            <div>{{ item.content }}</div>
          </div>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Цена</div>
            <div>
              <span class="font-weight-bold orange--text">{{ item.price | price }}</span>
              <span v-if="item.priceOld>0" class="ml-2 text-decoration-line-through">{{ item.priceOld | price }}</span>
            </div>
          </div>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Размер (см.)</div>
            <div>{{ itemSize }}</div>
          </div>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Описание</div>
            <div>{{ item.description }}</div>
          </div>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.back()">Назад</v-btn>
        <!--        <v-btn :to="{name: 'item.list'}">К списку</v-btn>-->
        <v-btn :to="{name: 'item.edit', param: { id }}">Изменить</v-btn>
      </v-card-actions>
    </v-card>

    <item-props-card :id="id" :item-props="item.props"></item-props-card>

    <v-card class="mt-2">
      <v-card-title>Категории</v-card-title>
      <v-card-text>
        <v-chip
          v-for="(c, index) in item.categories"
          :key="index"
          class="my-2 mr-1"
          :color="!c.isActive?'warning':null"
        >{{ c.name }}
        </v-chip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.back()">Назад</v-btn>
        <v-btn :to="{ name: 'item-category.edit', params: { id } }">Изменить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Теги</v-card-title>
      <v-card-text>
        <v-chip v-for="(t, index) in item.tags" :key="index" class="my-2 mr-1">{{ t.name }}</v-chip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.back()">Назад</v-btn>
        <v-btn :to="{ name: 'item-tag.edit', params: { id } }">Изменить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Изображения</v-card-title>
      <v-card-text>
        <div v-if="item.images.length>0" class="d-flex flex-wrap">
          <v-card v-for="(image) in item.images" :key="image.id" class="mr-1 mb-1">
            <v-img
              :src="image.image | imageUrl('medium', false)"
              height="350"
              max-width="350"
              class="rounded"
            >
            </v-img>
            <v-card-actions>
              <v-btn
                :color="image.isMain?'warning':'normal'"
                text
                x-small
                icon
                @click="setItemImageMain(image)"
              >
                <v-icon v-if="image.isMain">mdi-bookmark</v-icon>
                <v-icon v-else>mdi-bookmark-outline</v-icon>
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                text
                x-small
                icon
                @click="deleteItemImage(image)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-else class="">
          Пока ничего нет
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.back()">Назад</v-btn>
        <v-btn @click="showDialogUploadImage=true">Загрузить</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-2">
      <v-card-title>Видео обзор</v-card-title>
      <v-card-text>
        <div v-if="item.reviewVideo!=null" style="height: 500px" v-html="item.reviewVideo"></div>
        <div v-else class="font-italic">
          Пока ничего нет
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.back()">Назад</v-btn>
        <v-btn :to="{ name: 'item-video.edit', params: { id } }">Изменить</v-btn>
      </v-card-actions>
    </v-card>
    <item-yandex-market-card
      :id="id"
      :key="item.exportYandexMarket"
      :is-active="item.exportYandexMarket"
      :ym="item.yandexMarket"
      :disabled="busy"
      @toggle-activity="toggleExportYandexMarket"
    />
    <meta-view
      :id="metadata.id"
      :loading="busy"
      :created-at="metadata.createdAt"
      :updated-at="metadata.updatedAt"
    ></meta-view>
    <my-dialog-upload-item-image
      v-model="showDialogUploadImage"
      :item-id="id"
    />
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import MetaView from '@/components/ui/MetaView.vue'
import MyDialogUploadItemImage from '@/components/ui/dialog/MyDialogUploadItemImage.vue'
import ToastService from '@/service/ToastService'
import ItemYandexMarketCard from '@/pages/item/ItemYandexMarketCard.vue'
import ItemPropsCard from '@/pages/item/ItemPropsCard.vue'
import TextLineBool from '@/components/ui/TextLineBool.vue'
import configs from '@/configs'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemPage',
  components: { TextLineBool, ItemPropsCard, ItemYandexMarketCard, MyDialogUploadItemImage, MetaView },
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      showDialogUploadImage: false,
      metadata: {
        id: null,
        createdAt: null,
        updatedAt: null
      }
    }
  },
  computed: {
    ...mapState('item', ['item', 'busy']),
    itemCode() {
      if (!this.item) {
        return ''
      }
      if (this.item.codeBs) {
        return this.item.code + ' (' + this.item.codeBs + ')'
      }

      return this.item.code
    },
    itemSize() {
      const size = []

      if (this.item.length) {
        size.push('Длина: ' + this.item.length)
      }
      if (this.item.width) {
        size.push('Ширина: ' + this.item.width)
      }
      if (this.item.height) {
        size.push('Высота: ' + this.item.height)
      }

      return size.join(', ')
    },
    sheetColor() {
      if (this.item.isActive) {
        if (this.item.isInStock) {
          return null
        }
        else {
          return 'amber--text'
        }
      }
      else {
        return 'red--text'
      }
    },
    itemSiteLink() {
      return `${configs.siteUrl}/item/${this.id}`
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        // this.data.code = this.item.code
        // this.form.data.name = this.item.name
        // this.form.data.isActive = this.item.isActive

        this.metadata = {
          id: this.id,
          createdAt: this.item.meta.createdAt,
          updatedAt: this.item.meta.updatedAt
        }
      })
  },
  methods: {
    ...mapActions('item', ['fetchData', 'deleteImages', 'setMainImage','setYandexMarketActive']),
    deleteItemImage(image) {
      this.deleteImages({
        itemImageIds: [image.id]
      }).then((result) => {
        ToastService.success('Картинки удалены')
      }).catch((error) => {
        ToastService.error('Что-то пошло не так!')
        console.log(error)
      })
    },
    setItemImageMain(image) {
      this.setMainImage({
        itemImageId: image.id
      }).then((result) => {
        ToastService.success('Главная изображение установлено')
      }).catch((error) => {
        ToastService.error('Что-то пошло не так!')
        console.log(error)
      })
    },
    toggleExportYandexMarket(value) {
      this.setYandexMarketActive({ isActive: !this.item.exportYandexMarket })
        .then((result) => {
          ToastService.success('Данные по выгрузке изменены')
        }).catch((error) => {
          ToastService.error('Что-то пошло не так!')
          console.log(error)
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: this.itemCode
      }
    }
  }
}
</script>

<style scoped>

</style>
