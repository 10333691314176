<template>
  <div class="mb-2">
    <div class="text-caption grey--text text--darken-2">{{ title }}</div>
    <slot :value="value">
      <div v-if="value">
        {{ labelForTrue }}
      </div>
      <div v-else>
        {{ labelForFalse }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'TextLineBool',
  props: {
    title: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    value: {
      type: [String, Number, Boolean],
      default() {
        return ''
      }
    },
    labelForTrue: {
      type: [String],
      default() {
        return 'Да'
      }
    },
    labelForFalse: {
      type: [String],
      default() {
        return 'Нет'
      }
    }
  }
}
</script>
